<template>
    <sims-layout>
        <div class="sims-show-chat">
            <!-- Back -->
            <div style="margin-bottom: 20px">
                <a-button type="link" style="display: inline; margin-right: 15px" @click.prevent="goBack" icon="left">
                    Back to all
                </a-button>
            </div>
            <!-- / Back -->

            <!-- Details Card -->
            <div class="card">
                <div class="header">
                    <div class="flex-wrapper">
                        <div class="left">
                            <h3>Chat Details</h3>
                        </div>
                    </div>
                </div>
                <div class="body">
                    <!-- Loading -->
                    <div v-if="isLoading" class="text-center loading-wrapper">
                        <a-spin />
                    </div>
                    <!-- / Loading -->

                    <!-- No chat -->
                    <div v-if="(!isLoading && !chat)" class="no-chat-wrapper">
                        <a-alert type="error" message="Error loading chat, please try again." />
                    </div>
                    <!-- / No chat -->

                    <!-- Basic Details -->
                    <div v-if="(!isLoading && chat)">
                        <div class="page-header">
                            <h1 class="page-title">
                                {{ chat.displayName }}
                            </h1>
                        </div>
                    </div>
                    <!-- / Basic Details -->
                </div>
            </div>
            <!-- / Details Card -->

            <!-- Row -->
            <a-row :gutter="30" v-if="(!isLoading && chat)">
                <a-col :span="16">
                    <!-- Timeline -->
                    <div class="card">
                        <div class="header">
                            <h3>Messages</h3>
                        </div>
                        <div class="body body-bb timeline-container">
                            <timeline :membership="membership" :owner-type="2" :owner-id="chat.id"
                                :timeline="timelineGroupedByDate" :my-presence-id="myPresenceId" :tenant-id="tenantId">
                            </timeline>
                        </div>
                        <div class="body">
                            <typing-indicators :my-presence-id="myPresenceId"></typing-indicators>


                            <!-- Has Presence (not archived) -->
                            <div class="bottom-toolbar-wrapper">
                                <div class="left">
                                    <send-message-actions type="chat" @add-staff-members="addStaffMembers"
                                        :membership="membership" :tenant-id="tenantId" :org-id="chat.ownerId"
                                        :my-presence-id="myPresenceId"></send-message-actions>
                                </div>
                                <div class="right">
                                    <send-message :my-presence-id="myPresenceId"></send-message>
                                </div>
                            </div>
                            <!-- / Has Presence -->
                        </div>
                    </div>
                    <!-- / Timeline -->
                </a-col>
                <a-col :span="8">
                    <div class="rhs-card">
                        <div class="body rhs-sidebar">
                            <expanded-sidebar></expanded-sidebar>
                        </div>
                    </div>
                </a-col>
            </a-row>
            <!-- / Row -->
        </div>
    </sims-layout>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import simsLayout from "../simsLayout.vue";
// const _ = require("lodash");

import ExpandedSidebar from "../../Chats/Show/ExpandedSidebar.vue";
import SendMessage from "../../../components/Timelines/SendMessage.vue";
import Timeline from "../../../components/Timelines/Timeline.vue";
import SendMessageActions from "../../../components/SendMessageActions.vue";
import chats from "../../../api/chats";
import TypingIndicators from "../../../components/TypingIndicators.vue";

export default {
    components: {
        simsLayout,
        ExpandedSidebar,
        SendMessage,
        Timeline,
        SendMessageActions,
        TypingIndicators,
    },

    data() {
        return {
            resellerUpdateKey: 1,

            selectedTab: "messages",
            rightbarCollapsed: true,

            isEditingName: false,
            newName: "",
            isSavingName: false,

            isAddingChecklist: false,
            isAddingStaffMembers: false,

            justCreatedIncident: false,

            notifyArrivalHeartbeatEnabled: false,
        };
    },

    watch: {
        chat: {
            handler(newVal, oldVal) {
                if (newVal) {
                    if (!oldVal || oldVal.id !== newVal.id) {
                        this.setMetadataIds(newVal.metadataIds ? newVal.metadataIds : []);
                        this.loadTimeline({
                            model: "timeline",
                            tenantId: this.tenantId,
                            guid: newVal.id,
                            ownerType: 2,
                            ownerId: newVal.id,
                            organisationId: newVal.ownerId,
                            behaviour: 0,
                        });
                        this.loadQuickMessages();

                        if (!this.notifyArrivalHeartbeatEnabled) {
                            this.notifyArrivalHeartbeatEnabled = true;
                            this.notifyArrival();
                            this.updateMyLastReadPointer();
                        }
                    }
                }
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters("chatViewer", {
            chat: "chat",
            isLoading: "isLoading",
            myPresenceId: "myPresenceId",
            membership: "membership",
            canEditChat: "canEditChat",
            isManagedGroup: "isManagedGroup",
            isLoadingMembership: "isLoadingMembership",
        }),

        ...mapGetters("timeline", {
            timelineGroupedByDate: "timelineGroupedByDate",
        }),

        ...mapGetters("identifier", {
            guid: "guid",
        }),

        ...mapGetters("missionControl", {
            isReseller: "isReseller",
        }),

        tenantId() {
            return this.$route.params.tenantId;
        },

        fromAdmin() {
            return this.$route.query.fromAdmin;
        }
    },

    methods: {
        ...mapActions("chats", {
            updateChatGroupMember: "updateChatGroupMember",
        }),

        ...mapActions("chatViewer", {
            loadChat: "loadChat",
        }),

        ...mapActions("metadata", {
            setMetadataIds: "setMetadataIds",
            addGuidToMetadata: "addGuidToMetadata",
        }),

        ...mapActions("timeline", {
            loadTimeline: "loadTimeline",
            loadQuickMessages: "loadQuickMessages",
        }),

        expandRightSidebar() {
            this.rightbarCollapsed = false;
        },

        collapseRightSidebar() {
            this.rightbarCollapsed = true;
        },

        listenToSignalRControlMessage() {
            let vm = this;
            this.$signalR.on("ControlMessage", (controlMessage) => {
                console.log("Control Message:");
                console.log(controlMessage);

                let messageBody = JSON.parse(controlMessage["messageBody"]);
                // let groupId = messageBody["groupId"];
                let presenceId = messageBody["presenceId"];

                // if (groupId !== this.incident.id) {
                // return false
                // }

                // Set presence active
                if (controlMessage.messageType === 3) {
                    this.setPresenceActive(presenceId);
                }

                // Set presence inactive
                if (controlMessage.messageType === 4) {
                    this.setPresenceInactive(presenceId);
                }

                // Update group member
                if (controlMessage.messageType === 13) {
                    let groupMemberDetails = messageBody.groupMemberDetails;
                    if (groupMemberDetails.groupId === this.chat.id) {
                        this.updateChatGroupMember(groupMemberDetails);
                    }
                }

                // Closed
                if (controlMessage.messageType === 6) {
                    if (controlMessage.targetGroupId === this.chat.id) {
                        vm.$message.info("Chat has been closed");
                        vm.$router.push("/sims/chats");
                    }
                }
            });
        },

        listenToSignalRParticipantAddedMessage() {
            this.$signalR.on("AddParticiant", (participantAddedMessage) => {
                console.log("Participant Added");
                console.log(participantAddedMessage);

                let tenantId = participantAddedMessage.tenantId;
                let groupMemberDetails = participantAddedMessage.groupMemberDetails;

                let groupId = groupMemberDetails.groupId;
                if (tenantId === this.tenantId && groupId === this.chat.id) {
                    this.handleParticipantAdded(groupMemberDetails);
                }
            });
        },

        addMeToIncident() {
            if (this.isAddingStaffMembers) {
                return false;
            }
            this.addStaffMembers([this.myPresenceId], true);
        },

        addStaffMembers(staffMemberIds) {
            let vm = this;
            vm.isAddingStaffMembers = true;
            chats
                .addMembersToChat(
                    this.tenantId,
                    this.chat.id,
                    staffMemberIds,
                    this.myPresenceId
                )
                .then(() => {
                    vm.isAddingStaffMembers = false;
                    vm.$message.success("Staff members added successfully");
                })
                .catch((e) => {
                    console.log(e);
                    vm.isAddingStaffMembers = false;
                    vm.$message.error("Error adding staff members to chat");
                });
        },

        async notifyArrival() {
            if (!this.notifyArrivalHeartbeatEnabled) {
                return false;
            }
            if (this.$route.name !== "View Chat") {
                this.notifyArrivalHeartbeatEnabled = false;
                return false;
            }
            try {
                let params = {
                    InstallationId: this.guid,
                    CurrentTenantId: this.tenantId,
                    CurrentGroupId: this.chat.id,
                    CurrentPresenceId: this.myPresenceId,
                };
                console.log("Sending NotifyClientAvailable heartbeat:");
                console.log(params);

                await this.$signalR.invoke("NotifyClientAvailable", params);
                console.log("Heartbeat sent successfully");
            } catch (err) {
                console.log("Error notifying arrived");
                console.log(err);
            }

            // Let's trigger another heartbeat in 60 seconds
            let vm = this;
            window.setTimeout(function () {
                vm.notifyArrival();
            }, 60000);
        },

        async notifyDepart() {
            try {
                let params = {
                    InstallationId: this.guid,
                    CurrentTenantId: null,
                    CurrentGroupId: null,
                    CurrentPresenceId: null,
                };
                console.log("Sending NotifyClientAvailable departure:");
                console.log(params);

                await this.$signalR.invoke("NotifyClientAvailable", params);
                console.log("Depart message sent successfully");
            } catch (err) {
                console.log("Error notifying departed");
                console.log(err);
            }
        },

        updateMyLastReadPointer() {
            let vm = this;
            this.updateChatGroupMember({
                presenceId: vm.myPresenceId,
                groupId: vm.chat.id,
                groupMemberDetails: {
                    lastReadPointer: window.moment(),
                },
            });
            this.updateServerLastReadPointer(
                vm.tenantId,
                vm.myPresenceId,
                vm.chat.id
            );
        },

        subscribeIfAdminMode() {
        },

        unsubscribeIfAdminMode() {
            // TODO: add admin mode if statement (& not a member)
            try {
                this.$signalR.invoke("RemoveObserver", {
                    TenantId: this.tenantId,
                    GroupId: this.$route.params.id,
                });
            } catch (err) {
                console.log(err);
            }
        },

        goBack() {
            this.$router.push("/sims/chats");
        },
    },

    created() {
        let idFromUrl = this.$route.params.id;
        let isThisChatLoaded = this.chat && this.chat.id === idFromUrl;
        if (!isThisChatLoaded) {
            this.loadChat({
                tenantId: this.tenantId,
                id: idFromUrl,
            });
        }
    },
};
</script>
  
<style scoped lang="scss">
.sims-show-chat {
    padding-bottom: 20px;
}

.card {
    margin-bottom: 20px;
}

.bottom-toolbar-wrapper {
    display: flex;

    .is-archived {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 0 8px rgb(0 0 0 / 4%);
        flex: 1;
    }

    .add-me-to-group {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        display: flex;
        box-shadow: 0 0 8px rgb(0 0 0 / 4%);
        flex: 1;

        .left {
            flex-grow: 1;

            b {
                font-weight: 500;
            }
        }

        .right {
            flex-shrink: 1;
            padding-left: 15px;
            text-align: right;
        }
    }

    .left {
        flex-shrink: 1;
        padding-right: 15px;
        display: flex;
        align-items: center;
    }

    .right {
        flex-grow: 1;
    }
}

.timeline-container {
    max-height: 400px;
    overflow-y: scroll;
}

.page-header {
    margin-bottom: 0;

    h1 {
        margin-bottom: 8px;
    }
}

.rhs-card {
    border: 1px solid #ebedef;
    border-radius: 2px;
    overflow: hidden;

    .body {
        background: #fff;
        padding: 5px;

        &.bb {
            border-bottom: 1px solid #ebedef;
        }
    }
}


.flex-wrapper {
    display: flex;
    width: 100%;

    .left {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }

    .right {
        flex-shrink: 1;
        display: flex;
        align-items: center;
    }
}
</style>